import { Navigate, useLocation } from "react-router-dom";

import { PublicPaths } from "../routes/path";
import React from "react";
import { isAuthenticated } from "../utils";
import { ToastContainer } from "react-toastify";

const AuthGuard = ({ children }) => {
  const location = useLocation();
  if (isAuthenticated()) {
    return <>{children}</>;
  }
  return<>
  <ToastContainer/>
  <Navigate to={PublicPaths.HOME} state={{ from: location }} replace />
  </> ;
};

export default AuthGuard;
