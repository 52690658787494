import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TbFaceId } from "react-icons/tb";
import { BsQrCode } from "react-icons/bs";
import { RiAdminFill } from "react-icons/ri";
import { PublicPaths } from "../../routes/path";
import Modal from "../../components/Modal";
import { useMsal } from "@azure/msal-react";
import styles from "./styles.module.css";
import "../../shared/button.css";
import lban from "../../assets/logo.jpeg";
import { ReactComponent as LogoGreenText } from "../../assets/logo-green-text.svg";
import { FaMicrosoft } from "react-icons/fa";
import { errorAlert, successAlert } from "../../utils";
import { toast } from "react-toastify";
import { useClockin } from "../QRCode/Clockin/hooks";
import { useClockout } from "../QRCode/Clockout/hooks";
import { useIsMutating } from "@tanstack/react-query";
import { ThreeDots } from "react-loader-spinner";
import { useGetSettingById, useGetSettings } from "../Admin/hooks";
// import FingerprintJS from "fingerprintjs";
export const msalConfig = {
  auth: {
    clientId: "895bb9cd-ce76-495a-8fc9-da0479f8c1ab",
    authority: "https://login.microsoftonline.com/common",
    // redirectUri: "https://obladmin.nmfb.com.ng/.auth/login/aad/callback", // Update with your redirect URI
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

const Home = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [clockIn, setClockIn] = useState(false);
  const { instance, inProgress, accounts } = useMsal();
  const loading = useIsMutating();

  // const { data: settingsID = [] } = useGetSettings()?.data;
  const settingsList = useGetSettings()?.data;
  const settings = settingsList?.map((x) => x)?.shift();

  const useFacial = settings?.use_facial_recognition;
  const useQrCode = settings?.use_qr_code;
  const useLocation = settings?.use_location;
  const useAuth = settings?.use_ms_auth;
  console.log({
    settings,
    useFacial,
    useQrCode,
    useLocation,
    useAuth,
  });
  // const ID = settingsID && settingsID[0].id;

  // const { data } = useGetSettingById(ID);
  // console.log({
  //   settingsID: settingsID,
  //   // "settings[0]": settingsID && settingsID[0],
  //   data,
  // });

  const closeHandler = () => {
    setOpen(false);
    setClockIn(false);
  };
  const clockinHandler = () => {
    setOpen(true);
    setClockIn(true);
  };

  const clockOutHandler = () => {
    setOpen(true);
    setClockIn(false);
  };

  const clockInQR = () => {
    navigate(PublicPaths.QR_CLOCK_IN);
  };

  const clockInFace = () => {
    navigate(PublicPaths.CLOCK_IN);
  };

  const clockOutFace = () => {
    navigate(PublicPaths.CLOCK_OUT);
  };

  const clockOutQr = () => {
    navigate(PublicPaths.QR_CLOCK_Out);
  };

  const [long, setLong] = useState("");
  const [lat, setLat] = useState("");

  useEffect(() => {
    const getLocation = () => {
      if (!navigator.geolocation) {
        return toast.error("Geolocation is not supported by your browser");
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLong(position.coords.longitude.toString());
            setLat(position.coords.latitude.toString());
          },
          () => {
            return toast.error("Unable to retrieve your location");
          }
        );
      }
    };
    getLocation();
  }, []);

  //  if (!formData["lat"] || !formData["long"]) {
  //    return <h1>Please turn on your location</h1>;
  //  }

  const { mutate, reset, isSuccess, isError, error } = useClockin();

  const {
    mutate: clockout,
    reset: cReset,
    isSuccess: isCSuccess,
    isError: isCError,
    error: cError,
  } = useClockout();

  const handleLogin = async () => {
    try {
      const loginResponse = await instance.loginPopup({
        scopes: ["User.Read"],
      });

      // loginPopup returns the same response as acquireTokenSilent, so you can use it directly
      const accessToken = loginResponse?.account?.username;
      sessionStorage.clear();
      localStorage.clear();
      console.log(accessToken, "loginResponse");
      if (!long || !lat) {
        return toast.error("Please turn on your location to proceed");
      }
      const formData = {
        qrCode: accessToken?.toLowerCase(),
        long: long,
        lat: lat,
      };
      console.log(formData, "data");
      // Mutate the access token
      if (clockIn) {
        mutate(formData);
      } else {
        clockout(formData);
      }
      return accessToken;
    } catch (error) {
      errorAlert(error);
      console.error("Error during login:", error);
    }
  };

  if (isSuccess) {
    reset();
    closeHandler(); // close the modal
    successAlert("Clockin Successful");
  }

  if (isError) {
    reset();
    closeHandler();
    errorAlert(error);
  }

  if (isCSuccess) {
    cReset();
    closeHandler();
    successAlert("Clockout Successful");
  }

  if (isCError) {
    cReset();
    closeHandler();
    errorAlert(cError);
  }

  return (
    <div className={styles.home}>
      <nav className={styles.homeNav}>
        <div className={styles.logo}>
          <img src={lban} alt="logo" />
          {/* <AARanoLogo /> */}
        </div>

        <div className={styles.admin}>
          {/* <Link to={PublicPaths.ADMIN_LOGIN}>
            <RiAdminFill />
            Admin Login
          </Link> */}
        </div>
      </nav>
      <main>
        <div className={styles.title}>
          <h1>
            Attendance
            <br />
            Management
            <br />
            System
          </h1>
        </div>
        <div className={styles.btnWrapper}>
          <div className="btnContainer">
            <button className="btn btnSuccess" onClick={clockinHandler}>
              Clock In
            </button>

            <button className="btn btnPurple" onClick={clockOutHandler}>
              Clock Out
            </button>
          </div>
          {/* <div className={styles.registerLink}>
            Not yet registered?
            <Link to={PublicPaths.REGISTER}>SignUp here</Link>
          </div> */}
        </div>
      </main>

      <Modal
        isVisible={open}
        title={
          <div style={{ fontSize: "18px" }}>
            {" "}
            {clockIn ? "Clock In with?" : "Clock Out with ?"}
          </div>
        }
        size="md"
        content={
          loading ? (
            <div>
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#C21F4A"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
              <p>Loading....</p>
            </div>
          ) : (
            <div className={styles.modalContainer}>
              {useAuth && (
                <div className={styles.face} onClick={handleLogin}>
                  O365
                  <FaMicrosoft />
                </div>
              )}
              {useFacial && (
                <div
                  className={styles.face}
                  onClick={clockIn ? clockInFace : clockOutFace}
                >
                  Face
                  <TbFaceId />
                </div>
              )}
              {useQrCode && (
                <div
                  className={styles.face}
                  onClick={clockIn ? clockInQR : clockOutQr}
                >
                  QR Code <BsQrCode />
                </div>
              )}
            </div>
          )
        }
        onClose={closeHandler}
        footer=""
      />
    </div>
  );
};

export default Home;
