import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { getLoginToken } from "../storage";
// import { AxiosError } from "axios";

const SERVER_ERROR = "There was an error contacting the server.";

export const getDecodedJWT = () => {
	try {
		const token = getLoginToken();
		const decoded = jwtDecode(token);
		return decoded;
	} catch (e) {
		return null;
	}
};

export const isAuthenticated = () => {
	try {
		const decode = getDecodedJWT();
		if (decode) {
			const { exp } = decode;
			const currentTime = Date.now() / 1000;
			return exp > currentTime;
		}
		return false;
	} catch (e) {
		return false;
	}
};

export const toastOptions = {
	position: toast.POSITION.BOTTOM_CENTER,
	// autoClose: 8000,
	draggable: true,
	//   theme: "dark",
	// timeOut: 8000,
	pauseOnHover: true,
	style: {
		zIndex: "9999",
		
	},
};

export const successAlert = (msg) => {
	toast.success(msg || "Successfully created", toastOptions);
};
export const errorAlert = (error) => {
	const err =
		error?.response?.data?.detail || error?.response?.data
			? error?.response?.data?.detail || error?.response?.data
			: SERVER_ERROR;
	toast.error(err, toastOptions);
};
export const infoAlert = (msg) => {
	toast.info(msg || "Info Notification !", toastOptions);
};

// STUB: convert image to base64
export function dataURLtoFile(dataurl, filename) {
	var arr = dataurl?.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
}
