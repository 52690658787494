import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "mtforms/dist/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./react-query";
import AuthContextProvider from "./context";
import { ToastContainer } from "react-toastify";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./modules/Home";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <ToastContainer />
    <AuthContextProvider>
      <MsalProvider instance={new PublicClientApplication(msalConfig)}>
        <App />
      </MsalProvider>
    </AuthContextProvider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
