import { axiosInstance } from "../../../axios-Instance";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getLoginToken } from "../../../storage";
import { errorAlert, successAlert, toastOptions } from "../../../utils";
import { toast } from "react-toastify";
import { queryKeys } from "../../../react-query/constants";

async function getUsers() {
	const data = await axiosInstance({
		url: "/user",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getLoginToken()}`,
		},
	});

	return data?.data;
}

async function getAbsentUsers(date = new Date()) {
  const data = await axiosInstance({
    url: `/user/absent?date=${date.toISOString()}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });

  return data?.data;
}

async function getUserById(formData) {
	const data = await axiosInstance({
		url: `/user/${formData}`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getLoginToken()}`,
		},
	});

	return data?.data;
}

async function updateUser(formData) {
	const data = await axiosInstance({
		url: `/user/${formData["id"]}`,
		method: "PATCH",
		data: formData,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getLoginToken()}`,
		},
	});
	return data;
}

async function deleteUser(formData) {
	const data = await axiosInstance({
		url: `/user/${formData}`,
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getLoginToken()}`,
		},
	});

	return data;
}

async function addAdmin(formData) {
	const data = await axiosInstance({
		url: "/user/admin",
		method: "POST",
		data: formData,
		headers: {
			"Content-Type": "multipart/form-data",
			Authorization: `Bearer ${getLoginToken()}`,
		},
	});

	return data?.data;
}

async function getAttendance() {
	const data = await axiosInstance({
		url: "/attendance",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getLoginToken()}`,
		},
	});

	return data?.data;
}

async function getSettings() {
	const data = await axiosInstance({
		url: "/settings",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getLoginToken()}`,
		},
	});

  console.log({data})

	return data?.data;
}

async function getSettingById(formData) {
	const data = await axiosInstance({
		url: `/settings/${formData}`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getLoginToken()}`,
		},
	});

	return data?.data;
}

async function updateSettings(formData) {
	const data = await axiosInstance({
		url: `/settings/${formData["id"]}`,
		method: "PATCH",
		data: formData,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getLoginToken()}`,
		},
	});
	return data;
}

async function addLocation(formData) {
	const data = await axiosInstance({
		url: "/location",
		method: "POST",
		data: formData,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getLoginToken()}`,
		},
	});

	return data?.data;
}

async function getLocation() {
	const data = await axiosInstance({
		url: "/location",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getLoginToken()}`,
		},
	});

	return data?.data;
}

async function getLocationById(formData) {
	const data = await axiosInstance({
		url: `/location/${formData}`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getLoginToken()}`,
		},
	});

	return data?.data;
}

async function updateLocation(formData) {
	const data = await axiosInstance({
		url: `/location/${formData["id"]}`,
		method: "PATCH",
		data: formData,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getLoginToken()}`,
		},
	});
	return data;
}
async function deleteLocation(formData) {
	const data = await axiosInstance({
		url: `/location/${formData}`,
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getLoginToken()}`,
		},
	});

	return data;
}

export function useGetAttendance() {
	const fallback = [];
	const { data = fallback } = useQuery({
		queryKey: [queryKeys.attendance],
		queryFn: () => getAttendance(),
		onError: (error) => {
			toast.error(error, toastOptions);
		},
	});
	return data;
}

export function useGetUsers() {
	const fallback = [];
	const { data = fallback } = useQuery({
		queryKey: [queryKeys.user],
		queryFn: () => getUsers(),
		onError: (error) => {
			toast.error(error, toastOptions);
		},
	});
	return data;
}

export function useGetAbsentUsers(date = new Date()) {
  const fallback = [];
  const { data = fallback } = useQuery({
    queryKey: [queryKeys.user, date.toLocaleDateString()],
    queryFn: () => getAbsentUsers(date),
    onError: (error) => {
      toast.error(error, toastOptions);
    },
  });
  return data;
}

export function useGetUserById(formData) {
	const fallback = {};
	const { data = fallback } = useQuery({
		queryKey: [queryKeys.user, formData],
		queryFn: () => getUserById(formData),
	});
	return data;
}

export function useUpdateUser() {
	const queryClient = useQueryClient();
	const { mutate, isSuccess, reset, isError, error } = useMutation({
		mutationFn: (formData) => updateUser(formData),

		onSuccess: (data) => {
			queryClient.invalidateQueries([queryKeys.user]);
		},
	});
	return { mutate, isSuccess, reset, isError, error };
}

export function useDeleteUser() {
	const queryClient = useQueryClient();
	const { mutate, isSuccess, reset, isError, error } = useMutation({
		mutationFn: (formData) => deleteUser(formData),

		onSuccess: (data) => {
			queryClient.invalidateQueries([queryKeys.user]);
		},
	});
	return { mutate, isSuccess, reset, isError, error };
}

export function useAddAdmin() {
	const { mutate, isError, error, isSuccess, reset, data } = useMutation({
		mutationFn: (formData) => addAdmin(formData),

		onError: (error) => {
			errorAlert(error);
		},
	});
	return { mutate, isError, error, isSuccess, reset, data };
}

export function useGetSettings() {
	const fallback = [];
	const { data = fallback } = useQuery({
		queryKey: [queryKeys.settings],
		queryFn: () => getSettings(),
		onError: (error) => {
			toast.error(error, toastOptions);
		},
	});
	return data;
}
export function useGetSettingById(formData) {
	const fallback = {};
	const { data = fallback, isSuccess } = useQuery({
		queryKey: [queryKeys.settings, formData],
		queryFn: () => getSettingById(formData),
	});
	return { data, isSuccess };
}

export function useUpdateSettings() {
	const queryClient = useQueryClient();
	const { mutate, isSuccess, reset, isError, error } = useMutation({
		mutationFn: (formData) => updateSettings(formData),

		onSuccess: (data) => {
			queryClient.invalidateQueries([queryKeys.settings]);
		},
	});
	return { mutate, isSuccess, reset, isError, error };
}

export function useGetLocation() {
	const fallback = [];
	const { data = fallback } = useQuery({
		queryKey: [queryKeys.location],
		queryFn: () => getLocation(),
		onError: (error) => {
			toast.error(error, toastOptions);
		},
	});
	return data;
}
export function useAddLocation() {
  const queryClient = useQueryClient();
	const { mutate, isError, error, isSuccess, reset, data } = useMutation({
		mutationFn: (formData) => addLocation(formData),
		onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.location]);
			successAlert("Location added Successfully");
		},
		onError: (error) => {
			errorAlert(error);
		},
	});
	return { mutate, isError, error, isSuccess, reset, data };
}

export function useGetLocationById(formData) {
	const fallback = {};
	const { data = fallback } = useQuery({
		queryKey: [queryKeys.location, formData],
		queryFn: () => getLocationById(formData),
	});
	return data;
}

export function useUpdateLocation() {
	const queryClient = useQueryClient();
	const { mutate, isSuccess, reset, isError, error } = useMutation({
		mutationFn: (formData) => updateLocation(formData),

		onSuccess: (data) => {
			queryClient.invalidateQueries([queryKeys.location]);
		},
	});
	return { mutate, isSuccess, reset, isError, error };
}
export function useDeleteLocation() {
	const queryClient = useQueryClient();
	const { mutate, isSuccess, reset, isError, error } = useMutation({
		mutationFn: (formData) => deleteLocation(formData),

		onSuccess: (data) => {
			queryClient.invalidateQueries([queryKeys.location]);
		},
	});
	return { mutate, isSuccess, reset, isError, error };
}
