import { axiosInstance } from "../../../../axios-Instance";
import {  useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getLoginToken } from "../../../../storage";
import { errorAlert, toastOptions } from "../../../../utils";
import { toast } from "react-toastify";
import { queryKeys } from "../../../../react-query/constants";


async function userClockin(formData) {
  const data = await axiosInstance({
    url: `/attendance/sign-in-qr?content=${formData.qrCode}&long=${formData.long}&lat=${formData.lat}`,
    method: "POST",
    
    headers: {
      "Content-Type": "multipart/form-data",
     
    },
  });

  return data?.data;
}



async function getAttendance() {
  const data = await axiosInstance({
    url: "/Attendance/UserAttendStatus",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });

  return data?.data;
}



export function useClockin() {
  const queryClient = useQueryClient();
  const { mutate, isError, error, isSuccess, reset } = useMutation({
    mutationFn: (formData) => userClockin(formData),
    onSuccess: () => {
      toast.success("Clock in successfull", toastOptions);
      queryClient.invalidateQueries([queryKeys.clockIn]);
    },
    onError: (error) => {
        errorAlert(error, toastOptions);
    },
  });
 
  return { mutate, isError, error, isSuccess, reset };
}

export function useGetAttendance() {
  const fallback = [];
  const { data = fallback } = useQuery({
    queryKey: [queryKeys.clockIn],
    queryFn: () => getAttendance(),
    onError: (error) => {
      
      toast.error(error, toastOptions);
    },
  });
  return data;
}
