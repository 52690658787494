import { axiosInstance } from "../../../../axios-Instance";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { errorAlert, toastOptions } from "../../../../utils";
import { toast } from "react-toastify";
import { queryKeys } from "../../../../react-query/constants";

async function userClockout(formData) {
  const data = await axiosInstance({
    url: `/attendance/sign-out-qr?content=${formData.qrCode}&long=${formData.long}&lat=${formData.lat}`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data?.data;
}

export function useClockout() {
  const queryClient = useQueryClient();
  const { mutate, isError, error, isSuccess, reset } = useMutation({
    mutationFn: (formData) => userClockout(formData),
    onSuccess: (data) => {
      toast.success("Clock Out successfull", toastOptions);
      queryClient.invalidateQueries([queryKeys.clockIn]);
    },
    onError: (error) => {
        errorAlert(error, toastOptions);
    },
  });
  return { mutate, isError, error, isSuccess, reset };
}
