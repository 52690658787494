export const BasePaths = {
	MAIN: "/app",
	DASHBOARD: "/app/dashboard",
	CLOCK_IN: "/app/clockin",
	HOME: "/app/home",
	CLOCK_OUT: "/app/clockout",
	FACE: "/app/face",
};
export const PublicPaths = {
	HOME: "/",
	REGISTER: "/register",
	LOGIN: "/login",
	ADMIN_LOGIN: "/admin-login",
	ADMIN_REGISTER: "/admin-register",
	FORGOT_PASSWORD: "/forgot-Password",
	RESET_PASSWORD: "/reset-Password/:id",
	CLOCK_IN: "/clock-in",
	CLOCK_OUT: "/clock-out",
	QR_CLOCK_IN: "/qr-clock-in",
	QR_CLOCK_Out: "/qr-clock-out",
};

export const PrivatePaths = {
	DASHBOARD: "/app/dashboard",
	CLOCK_IN: "/app/clockin",
	CLOCK_OUT: "/app/clockout",
	HOME: "/app/home",
	ADMIN_DASHBOARD: "/app/admin/dashboard",
	ADMIN_REPORT: "/app/admin/report",
	ADMIN_USERS: "/app/admin/users",
	ADMIN_USER_HISTORY: "/app/admin/user",
	ADMIN_SETTINGS: "/app/admin/settings",
	ADMIN_SETTINGS_LOCATION: "/app/admin/settings/location",
	ADMIN_SETTINGS_AUTHENTICATION: "/app/admin/settings/auth",
	ADMIN_SETTINGS_TRAINING: "/app/admin/settings/training",
};
